@media (min-width: 410px) and (max-width: 440px) {
    .custom-mt {
      margin-top: -130px;
    }
  }

  .animate-slide-left {
    animation: slide-left 1s ease-in-out;
  }

  @keyframes slide-left {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .animate-typewriter {
    overflow: hidden;
    white-space: nowrap;
    animation: typewriter 1s steps(40) 1s forwards;
  }

  @keyframes typewriter {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }